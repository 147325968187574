<template>
  <div class="me-action-input" id="myinput">
    <div class="inner">
    <van-popup v-model="show" position="bottom" get-container="#myinput">
      <ul class="me-action-input__emotion">
        <li
          v-for="(item, index) in items"
          :key="index"
          @click="addEmotionImg($event, item, getFile(item.name))"
        >
          <img :src="getFile(item.name)" :face-name="item.name" />
        </li>
      </ul>
    </van-popup>
    <div
      class="me-action-input__editor"
      contenteditable
      ref="textarea"
      @blur="_inputBlur"
      @input="_inputListen"
      @click="show = false"
    ></div>
    <button class="add-emotion-btn"><i class="me-icon-smill icon" @click="showEmotion()"></i></button>
    <van-uploader :after-read="afterUploader" v-if="!content" :multiple="true">
      <i class="me-icon-plus-border icon"></i>
    </van-uploader>

    <van-loading class="send-loading icon" v-else-if="loading" size="32px" color="#ffffff" />
    <i
      class="me-icon-sender icon"
      v-else
      @click="curUser.type === 'group' ? handlerSenderGroupMsg() : handlerSenderChatMsg()"
    ></i>
    </div>
  </div>
</template>
<script>
import emotionData from "@/components/common/emotion/data";
import { getFile } from "@/utils/help";
import { senderGroupMSG } from "@/api/imchat";
import UUID from "uuidjs";
import { env, getBrowserInfo, parseTime } from "@/utils";
const browserInfo = getBrowserInfo();
import { getUploadToken } from "@/api/qiniu";
import * as qiniu from "qiniu-js";
export default {
  name: "action-input",
  data() {
    this.prevCursorPosition = 0;
    return {
      message: "",
      show: false,
      items: [],
      content: "",
      curUser: {},
      language: "1",
      loading: false,
      uploadImg: [],
      isLock:false
    };
  },
  watch: {},
  created() {
    this.items = emotionData;
    this.curUser = localStorage.getItem("chatObj")
      ? JSON.parse(localStorage.getItem("chatObj"))
      : {};
    this.language = localStorage.getItem("langCode") || "1";
  },
  mounted() {
    this.content = this.$refs.textarea.innerHTML;    
  },
  methods: {
		showEmotion(){
			this.$refs.textarea.focus();
			this.show = !this.show;
		},
    getFile,
     onCompositionstart (e) {
			this.isLock = true
		},
		onCompositionend (e) {
			this.isLock = false
		},
    fixLineBreak(){
    var sel, range;
    if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();

            // Range.createContextualFragment() would be useful here but is
            // only relatively recently standardized and is not supported in
            // some browsers (IE9, for one)
            var el = document.createElement("div");
            el.innerHTML = '<br>';
            var frag = document.createDocumentFragment(),
                node, lastNode;
            while ((node = el.firstChild)) {
                lastNode = frag.appendChild(node);
            }
            var firstNode = frag.firstChild;
            range.insertNode(frag);

            // Preserve the selection
            if (lastNode) {
                range = range.cloneRange();
                range.setStartAfter(lastNode);
                range.collapse(true);
                sel.removeAllRanges();
                sel.addRange(range);
            }
        }
    } else if ((sel = document.selection) && sel.type != "Control") {
        // IE < 9
        var originalRange = sel.createRange();
        originalRange.collapse(true);
        sel.createRange().pasteHTML('<br>');
    }
},
    async afterUploader(file) {
      if (file.length) {
        file.map(async item => {
          await this.handlerUploader(item);
        });
      } else await this.handlerUploader(file);
    },
    async handlerUploader(files) {
      let file = files.file;
      let oldName = file.name;
      let type = file.type;
      const message = this.packageMessage(file);
      await this.$emit("uploading", message);

      const isvideo = ["audio", "video"];
      
        // process.env.NODE_ENV == "development"
        //   ? "tfile"
        //   :  线上环境 用测试包文件
      const bucket = !env('BUCKET') ? 'tfile': isvideo.some(val => file.type.indexOf(val) !== -1)
          ? "video"
          : "imfile";

      const config = await getUploadToken(file.name, bucket);

      const { randomFileName, token } = config.data;
      const [year, month] = parseTime(new Date(), "{y}-{m}").split("-");

      const observable = qiniu.upload(
        file,
        `${year}/${month}/${randomFileName}`,
        token,
        {
          fname: file.name
        },
        {
          useCdnDomain: true,
          region: qiniu.region.z2
        }
      );
      let item = {
        name: oldName,
        type,
        progress: 0,
        url: "",
        size: file.size
      };
      // console.log(item, file, "/item");
      observable.subscribe({
        next: uploadEvent => {
          item.progress = uploadEvent.total.percent.toFixed(2);
        },
        error: error => {
          console.log("error", error);
        },
        complete: res => {
          const baseurl = {
            tfile: env("UPLOAD_IMFILE"),
            video: env("UPLOAD_VIDEO"),
            imfile: env("UPLOAD_IMFILE")
          };
          item.url = `${baseurl[bucket]}/${res.key}`;
          const fileinfo = {
            name: oldName,
            size: file.size,
            id: message.id,
            url: `${baseurl[bucket]}/${res.key}`
          };
          setTimeout(() => {
            this.curUser.type === "group"
              ? this.handlerSenderGroupMsg(true, fileinfo)
              : this.handlerSenderChatMsg(true, fileinfo);
          }, 500);
        }
      });
    },
    _checkUploadType(type) {
      const image = ["image/jpeg", "image/png", "image/gif"];
      const video = ["video/mp4"];
      if (image.includes(type)) return "image";
      if (video.includes(type)) return "video";
      return "file";
    },
    packageMessage(file) {
      const userInfoStr = localStorage.getItem("userinfo");
      const { avatar, id, name } = JSON.parse(userInfoStr);
      return {
        fileName: file.name,
        fileSize: file.size,
        fromUser: { avatar, displayName: name, id },
        id: UUID.generate(),
        sendTime: new Date().getTime(),
        status: "send_going",
        type: this._checkUploadType(file.type),
        url: file.content
      };
    },
    handlerSenderChatMsg(isFile = false, file) {
      this.show = false;
      this.loading = true;
      const user = this.$store.state.User.userinfo;
      let protocal = {};
      if (!isFile)
        protocal = {
          name: user.name || "",
          messageId: UUID.generate(),
          data: this.handlerFilterText(this.content),
          // .replace(/<\/?.+?\/?>/gi,''),
          subType: "text",
          avatar: user.avatar
        };
      else {
        protocal = {
          avatar: user.avatar,
          fileName: file.name,
          fileSize: file.size,
          messageId: file.id,
          name: user.name || "",
          subType: "pic",
          url: file.url
        };
      }
      const packageData = {
        code: 10001,
        type: "chat",
        targetId: Base64.decode(this.$route.params.id.replace("pai_", "")),
        protocal
      };

      const data = this.packageSocketMessage(packageData);
      // return
      this.$send(data);
      this.loading = false;
      this.content = this.$refs.textarea.innerHTML = "";
    },
    handlerFilterText(content){
      return this.filterEmojiImage(content).replace(/<div><br><\/div>/g, "\n")
        .replace(/^(<div>)/g, "")
        .replace(/\t/g, " ")
        .replace(/<\/div><div>/g, "\n")
        .replace(/<div>/g, "\n")
        .replace(/<\/div>/g, "\n")
        .replace(/<br\s*\/?>/gi, "\n")
        .replace(/&nbsp;/g, "")
        //.replace(/(<br>\s*)+$/is, "")
        .replace(/<\/?.+?\/?>/g, "")
    },
    async handlerSenderGroupMsg(isFile = false, file) {
      this.show = false;
      const params = {
        mimeType: "text",
        excluder: "web",
        messageId: UUID.generate(),
        content: this.handlerFilterText(this.content),
        group_id: Base64.decode(this.$route.params.id.replace("pai_", "")),
        language: this.language,
        token: this.$store.state.User.userinfo.token
      };
      if (isFile) {
        params.fileSize = file.size;
        params.fileName = file.name;
        params.url = file.url;
        params.messageId = file.id;
        params.mimeType = "pic";
      }
      this.loading = true;
      if (isFile) {
        await senderGroupMSG(params);
      } else if (params.content) {
        const result = await senderGroupMSG(params).catch(e => {});
        if (result && result.code === 1) {
          this.content = this.$refs.textarea.innerHTML = "";
        }
      }
      this.loading = false;
    },
    packageSocketMessage(options) {
      const user = this.$store.state.User.userinfo;
      const { targetId, type, protocal, code, originalSenderId } = options;
      let header = {
        isAck: "1",
        cmd: "-1",
        isReceipt: 1,
        mimeType: protocal.subType
      };
      let to = "";
      if (type == "groupchat") {
        to = targetId + "@muc.melinked.com";
      } else if (type == "chat" || type == "repeal") {
        // 单聊
        to = targetId + "@melinked.com";
      }
      if (targetId && user.id == targetId) {
        header.excluder = "web";
        header.saveMode = "drop";
      }
      if (type == "repeal") {
        header.cmd = 20004;
      }
      const msg = {
        code,
        to,
        header,
        type,
        protocal,
        from: user.id + "@melinked.com/web_" + getBrowserInfo(),
        messageId: protocal.messageId
      };
      if (originalSenderId) {
        msg.originalSenderId = originalSenderId;
        if (!protocal.originalSenderId) {
          msg.protocal.originalSenderId = originalSenderId;
        } else {
          msg.protocal.originalSenderId = protocal.originalSenderId;
        }
        msg.sourceSender = msg.from;
      }
      return msg;
    },
    filterEmojiImage(content, trim = "&nbsp;") {
      if (!content) return content;
      return content.replace(
        new RegExp(`<img face-name=\"([^\"]*?)\" [^>]*>${trim}`, "gi"),
        "[!$1]"
      );
    },
    _inputListen(e) {
      this.content = e.target.innerHTML.trim();
      
    },
    _inputBlur(e) {
      this.prevCursorPosition = this._getCursorPosition(e.target);
    },
    addEmotionImg(event, data, file) {
      const textarea = this.$refs.textarea;

      //对于文本消息，需要过滤表情、并移除HTML标签
      //var txt = content.data.replace(/\[!\w+\]/ig, '[' + helome.i18n.get('chat_page_tool_emotion') + ']');
      this._setCursorPosition(textarea, this.prevCursorPosition);
      this._insertElementToCursorAfter(
        textarea,
        `<img face-name="${data.name}" src='${file}' />&nbsp;`
      );
      this.content = this.$refs.textarea.innerHTML;
      // this.filterEmojiImage(this.$refs.textarea.innerHTML)
    },
    //在光标之后插入元素
    _insertElementToCursorAfter(cursorElem, elem) {
      let range, node;
      if (!cursorElem.hasfocus) {
        cursorElem.focus();
      }
      if (window.getSelection && window.getSelection().getRangeAt) {
        range = window.getSelection().getRangeAt(0);
        range.collapse(false);
        node = range.createContextualFragment(elem);
        let c = node.lastChild;
        range.insertNode(node);
        if (c) {
          range.setEndAfter(c);
          range.setStartAfter(c);
        }
        let j = window.getSelection();
        j.removeAllRanges();
        j.addRange(range);
      } else if (document.selection && document.selection.createRange) {
        document.selection.createRange().pasteHTML(elem);
      }
    },
    //获取光标位置
    _getCursorPosition(element) {
      var caretOffset = 0;
      var doc = element.ownerDocument || element.document;
      var win = doc.defaultView || doc.parentWindow;
      var sel;
      if (typeof win.getSelection != "undefined") {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0);
          var preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length;
        }
      } else if ((sel = doc.selection) && sel.type != "Control") {
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
      }
      return caretOffset;
    },
    //设置光标位置
    _setCursorPosition(element, pos) {
      const createRange = (node, chars, range) => {
        if (!range) {
          range = document.createRange();
          range.selectNode(node);
          range.setStart(node, 0);
        }
        if (chars.count === 0) {
          range.setEnd(node, chars.count);
        } else if (node && chars.count > 0) {
          if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < chars.count) {
              chars.count -= node.textContent.length;
            } else {
              range.setEnd(node, chars.count);
              chars.count = 0;
            }
          } else {
            for (let lp = 0; lp < node.childNodes.length; lp++) {
              range = createRange(node.childNodes[lp], chars, range);
              if (chars.count === 0) {
                break;
              }
            }
          }
        }
        return range;
      };
      if (pos >= 0) {
        const selection = window.getSelection();
        const range = createRange(element.parentNode, {
          count: pos
        });
        if (range) {
          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';
.add-emotion-btn{
	border none;
	padding 0;
	background-color transparent;
}
.send-loading {
  background: #33cc66;
  border-radius: 3rem;
  padding: 0;
  height: auto;
}

+b(me-action-input) {
 
  background: #f5f5f5;
  // padding: 0 0.5rem;
  border-top: 1px solid #e5e5e5;
  
  z-index: 3000;
  position:fixed
  bottom 0;
  width:100%
  .inner{
    width 96%;
    margin 0 auto
    display: flex;
    flex-flow: row;
    align-items: center;
    min-height: 54px;
    max-height: 120px;
  }

  .van-overlay {
    top: auto !important;
    bottom: 55px !important;
    z-index: 2007 !important;
  }

  .van-popup--bottom {
    bottom: 55px;
    z-index: 2008 !important;
  }

  +e(emotion) {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap !important;
    align-items: center;
    // justify-content: space-around;
    padding: 0.5rem;
    max-height: 180px;
    overflow-y: scroll;

    li {
      min-width: 30px;
      // margin: 0 0.5rem 0.5rem 0;
      margin: 0.5rem 0;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover;
      }
    }
  }

  +e(editor) {
    margin: 0.25rem 0;
    min-height: 30px;
    max-height: 100px;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 0.25rem;
    flex: 1;
    background: #ffffff;
    overflow-y: scroll;
    font-size: 14px;
    line-height: 22px;
  }

  +b(icon) {
    font-size: 32px;
    color: #999999;
    margin: 0 0.25rem;

    &.me-icon-sender {
      background: #33cc66;
      color: #ffffff;
      border-radius: 5rem;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      font-size: 22px;
    }
  }
}
</style>
