<template>
  <div class="message__file" @click="show = true">
    <van-loading v-if="!message.url && message.status==='send_going'" class="imgLoading" />
    <Thumb v-if="message.url" :src="formartImageUrl(message.url)" width="auto" height="120px"></Thumb>
    <van-image-preview v-model="show" :images="[formartImageUrl(message.url)]" @change="onChange"></van-image-preview>

    <!-- <span class="name">{{message.fileName}}-{{toBytesSize(message.fileSize)}}</span> -->
  </div>
</template>
<script>
import Thumb from "@/components/common/thumb";
import { formartImageUrl } from "@/utils/help";
import { toBytesSize } from "@/utils";
export default {
  name: "message-file",
  components: {
    Thumb
  },
  data() {
    return {
      show: false
    };
  },
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  created() {},
  methods: {
    formartImageUrl,
    toBytesSize,
    onChange() {
      this.show = !this.show;
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(message__file) {
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
	min-height 120px;

  .name {
    font-size: 12px;
    color: #999999;
    margin-top: 0.5rem;
  }

  .van-image {
    margin: 0 !important;
    flex: 1;
  }

  .imgLoading {
    width: 120px;
    height: 120px;
    padding: 0px;
    align-items: center;
  }
}
</style>