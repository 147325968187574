var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message__file",
      on: {
        click: function($event) {
          _vm.show = true
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "message__file--title",
          on: {
            click: function($event) {
              return _vm.handleContentClick()
            }
          }
        },
        [
          _vm.formartfileIcon(_vm.nameSfx).indexOf("file.") !== 0
            ? _c("Thumb", {
                attrs: {
                  src: _vm.formartfileIcon(_vm.nameSfx),
                  width: "45px",
                  height: "45px"
                }
              })
            : _c("i", { staticClass: "me-icon-file" }, [
                _vm._v(_vm._s(_vm.nameSfx))
              ]),
          _c("div", { staticClass: "info" }, [
            _c("span", { staticClass: "info__title" }, [
              _vm._v(_vm._s(_vm.message.fileName))
            ]),
            _c("span", { staticClass: "info__size" }, [
              _vm._v(_vm._s(_vm.toBytesSize(_vm.message.fileSize)))
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }