<template>
  <div class="message__file" @click="show = true">
    <!-- <Thumb :src="formartImageUrl(message.url)" width="100%" height="auto"></Thumb> -->
    <div class="message__file--title" @click="handleContentClick()">
      <Thumb
        :src="formartfileIcon(nameSfx)"
        width="45px"
        height="45px"
        v-if="formartfileIcon(nameSfx).indexOf('file.') !== 0"
      />
      <i class="me-icon-file" v-else>{{nameSfx}}</i>
      <div class="info">
        <span class="info__title">{{message.fileName}}</span>
        <span class="info__size">{{toBytesSize(message.fileSize)}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Thumb from "@/components/common/thumb";
import { formartImageUrl, formartfileIcon } from "@/utils/help";
import { toBytesSize, downloadFile, env } from "@/utils";
export default {
  name: "message-file",
  components: {
    Thumb
  },
  data() {
    return {
      show: false
    };
  },
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  created() {},
  computed: {
    nameSfx() {
      const name = this.message.fileName;
      if (!name) return "";
      const arr = name.split(".");
      let str = arr[arr.length - 1].toUpperCase();
      if (str.length > 3) {
        str = str.substring(0, 3);
      }
      return str;
    }
  },
  methods: {
    formartImageUrl,
    formartfileIcon,
    toBytesSize,
    onChange() {
      this.show = !this.show;
    },
    handleContentClick() {
      const url = `${env(
        "BASEURL"
      )}/rest/user/download?downloadUrl=${this.formartImageUrl(
        this.message.onlineurl || this.message.url
      )}&fileName=${encodeURIComponent(this.message.fileName)}`;

      downloadFile(url, this.message.fileName);
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(message__file) {
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;

  +m(title) {
    font-size: 12px;
    color: #999999;
    display: flex;
    flex-flow: row;
    max-width 100%

    .me-icon-file {
      position: relative;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      margin: 0.5rem 0.5rem 0 0;
      padding: 0.2rem 0.2rem 0 0;
      color: #1989fa;
      min-width: 45px;

      &:before {
        font-size: 50px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .van-image {
      margin: 0 0.5rem 0 0;
    }

    .info {
      display: flex;
      flex-flow: column;

      +e(title) {
        font-size: 14px;
        line-height: 16px;
        color: #333333;
        word-break break-word
      }

      +e(size) {
      }
    }
  }
}
</style>