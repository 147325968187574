<template>
  <div class="me-chat-msg-action" @click="showMenu = false">
    <Header>
      <i slot="left-one" class="me-icon-back" @click="$router.go(-1)"></i>
      <div slot="center" v-if="!params.notice" class="me-chat-msg-action__chatName" v-html="params.name && params.name.replace(/<\/?.+?\/?>/g, '')"> </div>
      <i slot="center" v-else  class="me-icon-bell" />
      <span
        v-if="langsType"
        slot="right-two"        
        @click="$router.push({name:'langs',params:{type:params.type, target:tranTarget}})"
      >{{$i18n.messages[langsType].langs[langsType]}}</span>
      <i slot="right-one" v-if="params.type ==='group' && !params.notice" class="me-icon-group" @click="$router.push({name:'chatGroupDetail',params:{id:params.id}})" />
      <i
				v-else-if="!params.notice"
        slot="right-one"
        class="me-icon-addGroup"
        @click="handlerUserCreateGroup()"
      ></i>
    </Header>
    <div class="me-chat-msg-action__members" v-if="params.type ==='group' && !params.notice">
      <Avatar
        v-for="(user,index) in groupMembers"
        :key="index"
        :src="user.profile_photo"
        :id="user.provider_id"
        width="40px"
        height="40px"
      />
    </div>
    <div :class="{'me-chat-msg-action__list':true,'isUser':params.type !=='group'}" ref="view"
      v-scroll="{
				onTop: _onTop,
				onBottom: _onBottom
			}"
    >
      <van-loading v-if="loading" class="chat-loading" color="#33cc66" />
      <van-cell v-for="(item,key) in list" :key="item.id" :title="item.userName" class="chat-msg-list"
      :ref="`msgItem-${key}`"
      @click="handlersetItem(item)">
      <!-- v-touch:longtap="vueTouch(item)"  -->
      <!-- v-longpress="handlerLongproess($event,item)" -->
        <div slot="title" class="van-cell__title--item">
          <template v-if="item.type !=='notice'">
            <template v-if="item.fromUser.id !== $store.state.User.userinfo.id">
              <Avatar
                :src="item.fromUser.avatar"
                width="30px"
                height="30px"
                v-if="item.fromUser.id !== '0000'"
                :id="item.fromUser.id"
              />
              <div class="van-cell__title--item--right">
                <div class="base" v-if="item.fromUser.id !== '0000'" >
                  <span>{{item.fromUser.displayName}}</span>
                  <span class="time">{{parseTime(item.sendTime)}}</span>
                </div>
                <div class="message">
                  <component :message="item" :is="`message-${item.type}`"></component>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="van-cell__title--item--right isMe">
                <div class="base" v-if="item.fromUser.id !== '0000'">
                  <span class="time">{{parseTime(item.sendTime)}}</span>
                  <span class="name">{{item.fromUser.displayName}}</span>
                </div>
                <div class="message">
                  <component :message="item" :is="`message-${item.type}`"></component>
                </div>
              </div>
              <Avatar
                :src="item.fromUser.avatar"
                width="30px"
                height="30px"
                style="margin-right:0;margin-left:.5rem"
                :id="item.fromUser.id"
                v-if="item.fromUser.id !== '0000'"
              />
            </template>
          </template>
          <component v-else :message="item" :is="`message-${item.type}`"></component>
        </div>
      </van-cell>
    </div>
    <ActionInput @uploading="handleraddMsg" v-if="!params.notice  && (params.type === 'group' ? isGroupMember: true)" />
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import { getChatRecordEXT, getTransTarget } from "@/api/imchat";
import { queryGroupInfoAndMembers } from "@/api/im";
import { getUserInfo } from "@/api/webApi";
import Avatar from "@/components/common/avatar";
import * as IMApi from "@/api/im";
import { getSuffix } from "@/utils/url";
import MessageText from "@/components/business/imchat/messages/message-text";
import MessageImage from "@/components/business/imchat/messages/message-image";
import MessageNotice from "@/components/business/imchat/messages/message-notice";
import MessageVoice from "@/components/business/imchat/messages/message-voice";
import MessageFile from "@/components/business/imchat/messages/message-file";
import MessageVideo from "@/components/business/imchat/messages/message-video";
import MessageShare from "@/components/business/imchat/messages/message-share";
import ActionInput from "@/components/business/imchat/action-input";
import { parseTime } from "@/utils";

// , env, getBrowserInfo, parseTime
// , close as serverClose

const components = {
  MessageText,
  MessageImage,
  MessageNotice,
  MessageVoice,
  MessageFile,
  MessageVideo,
  MessageShare
};
const typeImage = {
  nim_video1: "close-video",
  nim_video2: "video",
  nim_video3: "video-3",
  nim_voice1: "close-voice",
  nim_voice2: "voice",
  nim_voice3: "voice-3"
};
const voice_video = ["nim_video", "nim_voice"];

export default {
  name: "chatWiht",
  components: { Header, Avatar, ...components, ActionInput },
  data() {
    this.messageTypeMapRev = {
      voice: "multimedia",
      image: "pic",
      file: "file",
      text: "text"
    };
    this.messageTypeMap = {
      multimedia: "voice",
      video: "video",
      pic: "image",
      file: "file",
      text: "text"
    };
    return {
      params: {},
      language: "",
      loading: true,
      list: [],
      groupMembers: [],
      finished: false,
      pageSize: 10,
      pageDownDate:'',
      lastQueryDate: "",
      prePageLoading: true,
      curUser: {},
      catchAllMsg: {},
      tranTarget: "",
      langsType:'',
      chatUser:{},
      showMenu:false,
      timeOutEvent:0,
      finishedPageDown:false,
      menuStyle:{},
      isGroupMember:false
    };
  },
  computed:{
    style(){
      return this.menuStyle
    }
  },
  async created() {
    this.curUser = this.$store.getters.getUserinfo;
    this.language = localStorage.getItem("langCode") || "1";
    this.params = JSON.parse(localStorage.getItem("chatObj"));
    this.params.id = Base64.decode(this.params.id.replace("pai_", ""));
    this.pageDownDate = this.params.lastTime || ''
    this.list = await this.handlerGetMsglist();
    if(this.list.length > 1){
      await this.scrollToBottom();
    }  
    if (this.params.type === "chat" || this.params.type === 'user' || !this.params.type) {
       this.handlerGetUserInfo()
       this.handlerGetTran();}
    else if (this.params.type === "group"){
      this.handlerGetGroupTran();
    } 
  },
  watch:{
    tranTarget:{
      handler(val){
        localStorage.setItem('tranTarget',val)
      }
    }
  },
  mounted() {
    this.$listener(async socketData => {
      if (socketData.code == 2) { 
        this.$send({ code: 2 });
        return;
      }
      const data = { ...socketData };
      const target =  data.type !== "receipt" ? data.from.slice(0, data.from.indexOf("@")) : data.header.from.slice(0, data.header.from.indexOf("@"));
      try{
        if (!data.type || target.indexOf(Base64.decode(this.$route.params.id.replace("pai_", ""))) === -1) return;
      }catch(e){}
      const protocal = JSON.parse(data.protocal);
      const header = data.header;
      const now = new Date().getTime();
      const userId = data.sourceSender;
      const message = {
        content: data.protocal,
        type: data.type,
        messageId: data.messageId,
        header,
        msgNum: 0,
        userId: data.type !== "receipt" ? userId : this.$store.state.User.userinfo.id,
        userName: protocal.actor || protocal.name,
        avatar: protocal.avatar,
        msgTime: header.servertimestamp || protocal.subSendTime || now
      };
      if (
        data.type === "groupchat" &&
        this.groupMembers &&
        this.groupMembers.length
      ) {
        this.groupMembers.some(user => {          
          if (user.provider_id === data.sourceSender) {
            message.avatar = user.profile_photo;
          }
        });
      }
      const formartMsg = this.toImlinkMessageData([message])[0];
      
      let updateMessageIndex = null;
      if (
        this.list.some((item, index) => {
          console.log(item,'/item');
                
          if (item.id &&  item.id === message.messageId) {
            updateMessageIndex = index;
            return true;
          } else return false;
        })
      ) {
        if (updateMessageIndex !== null) {
          this.list[updateMessageIndex] = formartMsg;
          this.$forceUpdate();
        }
      } else {        
        this.list.push(formartMsg);
      }
     await this.scrollToBottom();

      // 当前聊天窗口的消息解析处理
      // console.log("it is me");
      // 多终端同步退出
      // if (header.cmd == 1) {
      //     localStorage.removeItem("userinfo");
      //     this.$store.dispatch("commitUserinfo", {});
      //     serverClose();
      //     await this.$http({
      //       url: "/helome/logout.do",
      //       method: "get"
      //     }).catch(e => {});
      //     this.$router.push({ name: "home" });
      //     return;
      //   }

      console.log(data, "get data");
      // this.toImlinkMessageData([protocal]);
    });
  },
  methods: {
    parseTime,
    touchstart(event){
      console.log(event,'/event');
      
    },
    handlersetItem(msg){
      // console.log(msg);
      
    },
    async handlerGetUserInfo(){
      const params = {
        language:this.language,
        userId:this.params.id
      }
      const result = await getUserInfo(params)
      if(result && result.code === 201){
        
        this.chatUser = result.data
        this.params.name = result.data.name
      }
        
    },
    
    async handlerGetGroupTran() {
      const result = await queryGroupInfoAndMembers(this.params.id);
      console.log(result)
      if (result && result.data) {
        this.tranTarget = result.data[0].translate_target;
        this.langsType = this.tranTarget ==='zh' ? 'zh-CN': this.tranTarget === 'ru' ? 'ru-RU' : this.tranTarget
      }
    },
    handlerUserCreateGroup(){
      localStorage.setItem('oldGroupMembers',JSON.stringify({id:this.params.id,members:[{...this.params,avatar:this.chatUser.profile_photo}]}))
       this.$router.push({
        name: "friends",
        query: { selectModel: true }
      });
    },
    async handlerGetTran() {
      const params = {
        pairU1: this.curUser.id,
        pairU2: this.params.id,
        language: this.language,
        token: this.curUser.token
      };
      const result = await getTransTarget(params).catch(e => {
        console.log(e);
      });
      if (result && result.code === 200) {
        this.tranTarget = result.data.data.translateType;
        this.langsType = this.tranTarget ==='zh' ? 'zh-CN': this.tranTarget === 'ru' ? 'ru-RU' : this.tranTarget
      }
      // https://www.melinked.com/rest/translate/type?pairU1=26174540626319361&pairU2=30919844145715201&language=1&token=603a7c27abdea8000eadf0a181db03827f6162b13c1b2591
      // 单聊获取翻译语言
    },
    async handleraddMsg(message) {
      console.log(message,'/msg');
      
      this.list.push(message);
      if(this.list.length > 3)
        await this.scrollToBottom();
    },
    async _onTop() {
      let prevScrollHeight;
      try{
        prevScrollHeight = this.$refs.view.scrollHeight - this.$refs.view.scrollTop;
      }catch(e){}
      this.loading = true;
      this.pageDownDate = ''
      this.lastQueryDate = this.list[0].sendTime;
      const list = await this.handlerGetMsglist(this.lastQueryDate); 
      
      this.loading = false;
      if (list.length) {
        this.list = list.concat(this.list);       
      }
      await this.$nextTick();
      this.scrollTo(this.$refs.view.scrollHeight - prevScrollHeight);
    },
    async _onBottom() {
      if(this.finishedPageDown) return
      this.lastQueryDate = '';
      this.pageDownDate =  this.list[this.list.length - 1].sendTime + 1
      console.log(this.lastQueryDate,this.pageDownDate,'/lastQueryDate');
      
      this.loading = true;
      const list = await this.handlerGetMsglist(this.lastQueryDate);
      console.log(list.length < this.pageSize,list.length , this.pageSize);
       this.loading = false;
      if(list.length < this.pageSize){
         this.pageDownDate = ''
         this.finishedPageDown = true
      }else{
        this.finishedPageDown = false
      }     
      this.list = this.list.concat(list);
    },
    scrollTo(val = "0px") {
      this.$refs.view.scrollTop = val;
    },
    async scrollToBottom() {
      await this.$nextTick();
      if (this.$refs.view) this.scrollTo(this.$refs.view.scrollHeight - 5);
    },
    async handlerGetMsglist() {
      const params = {
        pageIndex: 0,
        pageSize: this.pageSize,
        pageDownDate: this.pageDownDate,
        lastQueryDate: this.lastQueryDate,
        language: this.language,
        _:new Date().getTime()
      };
      if (this.params.type === "group") {
        params.groupId = this.params.id;
      } else {
        params.userId = this.params.id;
      }

      const result = await getChatRecordEXT(params).catch((e) => {
        console.log(e,'/error');
        
      });
      
      this.loading = false;
      if (result && result.data) {
        if (!result.data.list.length) this.finished = true;
        this.groupMembers = result.data.membersList;
        if(this.params.type === 'group')
          this.isGroupMember = this.groupMembers.some(el=>{            
            return el.provider_id === this.curUser.id
          })
        const list = this.toImlinkMessageData(result.data.list); 
        return list
      } else {
        return [];
      }

    },
    toImlinkMessageData(items) {
      // let data = [];
      // items.map(el => {
      //   const content = JSON.parse(el.content);
      //   if (content.actionType != 7 && content.actionType != 8) {
      //     data.push(el);
      //   }
      // });
      let messageList = items.map(item => {        
        const content = JSON.parse(item.content);
        const header =
          item.header && typeof item.header === "string"
            ? JSON.parse(item.header)
            : item.header;

        const message = {
          id: content.messageId,
          status: "send_succeed",
          fromUser: {
            id: item.userId,
            displayName: item.userName,
            avatar: item.avatar || ""
          },
          sendTime: item.msgTime,
          msgs: content.translateMessage
            ? typeof content.translateMessage === "string"
              ? JSON.parse(content.translateMessage)
              : content.translateMessage
            : {}
        };
        if (content.subType == "text" || content.type == "text") {
          message.type = "text";
          message.text = content.data;
        } else if (
          content.subType == "nim_video" ||
          content.subType == "nim_voice"
        ) {
          //console.log(content.subType + content.nim_state);
          message.type = "text";
          message.text = [
            <img
              style="width:26px;height:32px;display:inline-block"
              src={require(`assets/images/icon/${
                typeImage[content.subType + content.nim_state]
              }.png`)}
            />,
            content.nim_state == 2 ? content.data : ""
          ];
        } else {
          message.type = this.messageTypeMap[content.subType];
          if (message.type == "file") {
            message.type = this.messageTypeMap[
              this.urlSuffixToType(content.url)
            ];
          }
          if (message.type == "voice") {
            message.duration = content.multimediaLength;
            message.fileName = content.fileName;
            message.fileSize = content.fileSize;
          } else {
            message.fileName = content.fileName;
            message.fileSize = content.fileSize;
          }
          message.url = content.url;
          message.imgUrl = content.imgUrl || "";
        }
        if (!voice_video.includes(content.subType))
          message.translate = content.subDate;

        //console.log(item);
        // ==  && content.subSenderId !== item.userId
        if (content.subSenderId && content.previouslysaider) {
          message.translateUserId = content.subSenderId;
        }
        // 处理通知消息
        // content.actionType   1建群 2修改群名称 3成员入群 4成员出群 5群主退群 6设置翻译者 7发起群音视频 8挂断群音视频

        if (content.actionType !== undefined) {
          message.type = "notice";
          const mask = {
            1: "group-add",
            2: "group-update",
            3: "group-join",
            4: "group-quit",
            5: "group-quit",
            6: "translate",
            7: "startVideo",
            71: "startVoice",
            8: "stopVideo",
            81: "stopVoice"
          }[content.actionType];
          const img = `<img src="${require(`assets/images/icon/${mask}.png`)}">`;

          if (
            content.actionType == 4 ||
            content.actionType == 5 ||
            content.actionType == 6 ||
            content.actionType == 7 ||
            content.actionType == 71 ||
            content.actionType == 8 ||
            content.actionType == 81
          ) {
            message.text = content.actor + img;
            // this.$refs.chat.updateContact(item.groupId, {
            //   isExit: true
            // });
          } else {
            message.text = content.actor + img + content.groupName;
          }
        }

        //处理撤回消息
        if (header.cmd === 20100 || header.mimeType === "link") {
          const _uuid = content.uuid
          message.type = "share";
          message.subType = "link";
          message.catchMsg = item;
          message.text = ''
          message.shareData = {
            ...content
          }
        } else if (header.cmd == 20004) {
          message.type = "notice";
          const img = `<img src="${require(`assets/images/icon/msgrecall.png`)}">`;
          //console.log(content);
          //console.log(header);
          message.text = (content.name || content.actor) + img;

          // const chat = this.$refs.chat;
          // window._fillEditor = e => {
          //   chat.$refs.editor.setInput(
          //     this.$emoReplaceToImage(e.getAttribute("data"))
          //   );
          //   chat.$refs.editor.submitDisabled = false;
          // };

          if (
            item.userId == this.$store.state.User.userinfo.id &&
            header.mimeType == "text"
          ) {
            message.text += `<i class='el-icon--pen lemon-message__recall' data='${content.data.replace(
              /<[^>]*>|<\/[^>]*>/gi,
              ""
            )}' onclick="_fillEditor(this)" style='color:#52bd68;cursor:pointer'></i>`;
          }
        } else if (header.cmd == 40001) {
          ///申请查看历史消息
          message.type = "notice";
          message.text = (
            <span
              style="color:#52bd68;cursor:pointer;"
              on-click={e => {
                if (e.target.getAttribute("loading") != 1) {
                  e.target.setAttribute("loading", 1);
                  const params = {
                    groupId: item.groupId,
                    mimeType: "text",
                    content: "申请查看历史消息",
                    messageId: content.messageId,
                    excluder: "web",
                    cmd: 40002,
                    nodeTime: new Date().getTime()
                  };
                  IMApi.sendMsgtoMuc(params);
                }
              }}
            >
              <i class="me-icon-up" /> {"申请查看历史消息"}
            </span>
          );
        } else if (header.cmd == 40003) {
          ///申请查看历史消息
          message.type = "notice";

          message.text =
            content.groupOwnerId == this.user.id ? (
              <span>
                {content.data}
                <i
                  class="imelink-history-button me-icon-check"
                  on-click={() => {
                    const params = {
                      groupId: item.groupId,
                      mimeType: "text",
                      cotnent: content.actor,
                      messageId: content.messageId,
                      cmd: 40004,
                      actorId: content.actorId
                    };
                    IMApi.sendMsgtoMuc(params);
                  }}
                />
              </span>
            ) : (
              <span>{content.data}</span>
            );
        } else if (header.cmd == 40004) {
          message.type = "notice";
          message.text = content.data;
        } else if (header.cmd == 40005) {
          message.type = "notice";
          message.text = content.data;
        }

        const originId = content.originalSenderId || header.proxyuid;
        if (originId) {
          //   const params = {}
          //   const req = getUserInfo(params);
          //   req.then(data => {
          //     message.originalSenderId = originId;
          //     this.$refs.chat.updateMessage({
          //       id: message.id,
          //       originalSender: (
          //         <a
          //           onClick={() =>
          //             this.$router.push({
          //               name: "user",
          //               params: { id: message.originalSenderId }
          //             })
          //           }
          //         >
          //           {data.data.uc_id}
          //         </a>
          //       )
          //     });
              //this.$refs.chat.scrollToBottom();
          //   });
        }
        
        // console.log(item,'old message');
        return message;
      });
      // console.log(messageList,'/list');
      
      return messageList.filter(item => item.type);
    },
    formartShareData(protocal, uuid) {
      let routerName = protocal.serviceType === "people" ? "user" : "detail";
      let type = "0";
      if (protocal.serviceType === "group") {
        type = "4";
      } else if (protocal.serviceType === "company") {
        type = "5";
      }
      let msgHtml = [
        <div
          class="imelink-share-box"
          on-click={() => {
            let params = {
              id: "pai_" + uuid
            };
            if (type && protocal.serviceType !== "people") {
              params.type = type;
            }
            this.jumpPage(
              {
                name: routerName,
                params
              },
              null,
              true
            );
          }}
          data-id={uuid}
        >
          {protocal.title && (
            <span class="imelink-share-title">{protocal.title}</span>
          )}
          <p style="display: flex; flex-flow:row">
            {protocal.picurl && (
              <img src={protocal.picurl} class="imelink-share-img" />
            )}
            {protocal.briefInfo && (
              <span class="imelink-share-desc">{protocal.briefInfo}</span>
            )}
          </p>
        </div>
      ];
      return msgHtml;
    },
    urlSuffixToType(url) {
      if (!url) return "";
      url = url.toLowerCase();
      const sfx = getSuffix(url);
      const image = ["jpg", "jpeg", "png", "gif"];
      const video = ["mp4"];
      const multimedia = ["m4a", "wav"];
      if (image.includes(sfx)) {
        return "pic";
      } else if (video.includes(sfx)) {
        return "video";
      } else if (multimedia.includes(sfx)) {
        return "multimedia";
      } else {
        return "file";
      }
    }
    // handlerFormartMSG(message) {
    //   let msg = message;
    //   switch (message.subType) {
    //     case "text":
    //       msg.data = message.data;
    //       break;
    //     case "pic":
    //       msg = message;
    //       break;
    //   }
    //   return msg;
    // }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';
.me-chat-msg-action{
	height 100%;
	display: flex;
	flex-flow: column;
	position relative;
	.header{
	  height 44px;
		padding 0;
		position fixed;
		flex none;
	}
	.me-chat-msg-action__list {
		position fixed;
		top 44px;
		bottom: 59px;
		width 100%;
	}
  .isUser{
		position fixed;
		top 44px;
		bottom: 59px;
		width 100%;
	}
}

.chat-loading {
  padding: 0.5rem 0;
  height: 1rem;
}

+b(me-chat-msg-action) {

  +e(chatName) {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
  }

  +e(members) {
    margin: 44px 0 0 0;
    display: flex;
    flex-flow: row;
    padding: 0.5rem;
    overflow-x: scroll;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 20px 2px rgba(122, 122, 122, 0.1);
    z-index: 1;
  }
  +e(list) {
    flex: 1;
    overflow-y: scroll;

    .van-cell {
      background: none;

      &::after {
        border: 0;
      }

      +e(title) {
        +m(item) {
          display: flex;
          flex-flow: row;

          +m(right) {
            flex: 1;
            display: flex;
            flex-flow: column;
            align-items: flex-start;

            .base {
              span {
                color: #666666;

                &.time {
                  margin-left: 0.5rem;
                  font-size: 12px;
                }

                &.name {
                  font-size: 16px;
                }
              }
            }

            .message {
              background: #ffffff;
              border-radius: 0.3rem;
              position: relative;
              min-height: 24px;
              display: inline-block;

              &::after {
                content: '';
                position: absolute;
                left: -14px;
                top: 6px;
                width: 0;
                height: 0;
                font-size: 0;
                border: 8px solid;
                border-color: transparent;
                border-right-color: #ffffff;
              }
            }

            &.isMe {
              justify-content: flex-end;
              align-items: flex-end;

              .base {
                text-align: right;

                span {
                  &.time {
                    margin: 0 0.5rem 0 0;
                  }
                }
              }

              .message {
                background: #e6f5e9;

                &::after {
                  left: auto;
                  right: -14px;
                  transform: rotate(180deg);
                  border-right-color: #e6f5e9;
                }

                +m(subDate) {
                  background: #c6edcc;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>