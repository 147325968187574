<template>
  <div class="message__text" ref="messageText">
    <span class="message__text--original" v-html="replaceNameToImage(renderMsg.text)"></span>
    <template v-for="(item, index) in translates">
      <div 
        :class="['message__text--subDate',  handlerHasMore(item) ? 'trans' : '']" 
        :data-hasmore="handlerHasMore(item)" 
        @click="handlerToggleShowall"
        :key="index"
         v-if="renderMsg.msgs && item.language !== renderMsg.msgs.sourceLanguage"
        >
        <span
          class="subText"
          :key="index"
          v-html="replaceNameToImage(item.translateMsg)"
        ></span>
      </div>
    </template>
  </div>
</template>
<script>
import { isString } from "utils/validate";
import { getFile, replaceNameToImage } from "@/utils/help";
import Jquery from 'jquery'
export default {
  name: "message-text",
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      translates: [],
      renderMsg:{}
    };
  },
  created() {
    this.renderMsg = this.message
    this.formartText("text");
    if (this.renderMsg.translate) this.formartText("translate");
    if (this.renderMsg.msgs && this.renderMsg.msgs.msgs){
      this.translates = this.sortSelfTranslate(this.renderMsg.msgs);
    }
    // console.log(this.renderMsg.text,'////////');
  },
  methods: {
    replaceNameToImage,
    handlerToggleShowall(event){
      const isSelf = Jquery(event.target).attr('class').indexOf('message__text--subDate') != -1 ? true : false      
      const parent =isSelf ? Jquery(event.target) : Jquery(event.target).parents('.message__text--subDate')
      const hasmore = parent.data('hasmore')
      if(hasmore){
        // const classList = event.target.classList
        const parentClass = parent.attr('class')
        if(parentClass.indexOf('trans') !== -1)
          parent.addClass('showall').removeClass('trans')
        else parent.addClass('trans').removeClass('showall')
      }
    },
    handlerHasMore(item){
      return !item.self && item.translateMsg && item.translateMsg.length > (item.language !== 8 ? 30 : 20)
    },
    sortSelfTranslate(message) {
      let msgs = message.msgs;
      let temparray = [];
      let selfTransmsg = {}
      let tranTarget = localStorage.getItem('tranTarget') || 'zh'
      if(tranTarget === 'zh') tranTarget = 'zh-CN'
      if(tranTarget === 'ru') tranTarget = 'ru-RU'
      if (msgs) {
        msgs.map(item => {
          if (
          item.language !== this.message.sourceLanguage &&
          item.translateMsg &&
          item.language ==
            this.$i18n.messages[tranTarget].code
        ){ 
           selfTransmsg = { ...item, self: true };
        }         
        else        
            temparray.push(item);  
        });
        // console.log(selfTransmsg,'/selfTransmsg');
        
        if (Object.keys(selfTransmsg).length) temparray.unshift(selfTransmsg);
      }
      return temparray;
    },
    formartText(field) {
      if (typeof this.renderMsg[field] !== "string") return this.renderMsg[field];
      const uri_suffix = [
        "\\.com",
        "\\.cn",
        "\\.net",
        "\\.org",
        "\\.gov",
        "\\.edu",
        "\\.info",
        "\\.top",
        "\\.mil",
        "\\.rts",
        "\\.firm",
        "\\.link"
      ];
      const REG_URI = new RegExp(
        `((https://|http://)?([A-Za-z0-9]+(\\.[A-Za-z0-9]+){0,2}(${uri_suffix.join(
          "|"
        )})|[0-9]{3}(\\.[0-9]{1,3}){3})(:[0-9]{0,4})?(/[A-Za-z0-9@]*){0,}([^# ]*)([A-Za-z0-9@-]*(\\.jsp|\\.html))?(\\?[A-Za-z0-9@;_-]*=[A-Za-z0-9@%=_-]*(&[A-Za-z0-9@;_-]*=[A-Za-z0-9@%=_-]*){0,})?)`,
        "g"
      );
      if (isString(this.renderMsg[field])) {
        this.renderMsg[field] = this.renderMsg[field]
          .replace(REG_URI, function(match) {
            if (match.indexOf("'") === -1) {
              var href = match;
              if (href.indexOf("http") != 0) {
                href = "http://" + href;
              }
              return (
                "<a href='" +
                href +
                "' title='" +
                href +
                "' target='_blank'>" +
                match +
                "</a>"
              );
            } else {
              return match;
            }
          }).replace(/\r\n|\n/gi, "<br/>")
        this.$forceUpdate()
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(message__text) {
  display: flex;
  flex-flow: column;
  // word-break: break-all;
  font-size: 13px;

  // white-space: wrap;
  +m(original) {
    padding: 0.5rem;
    white-space:wrap;
    word-break break-word
  }

  +m(subDate) {
    // flex:1;
    padding: 0.5rem;
    color: #333333;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    border-top: 1px solid #f5f5f5;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break break-all

    &.trans{
      display flex;
      flex-flow row;
      align-items center;
      .subText{
        flex:1
        }

      &::after{
          font-family: iconfont!important;
          content: "\E694";
          margin-top: 5px;
          text-align: center;
          border-radius: 20px;
          background: #fff;
          line-height: 15px;
          color: #999;
          font-size: 16px;
          cursor: pointer;
      }
     
      .subText{
        width: 96%;        
        white-space: nowrap;
        text-overflow ellipsis
        overflow hidden
      }
    }
    &.showall{
        &::after{
          font-family: iconfont!important;
          content: "\e80a" !important;
          margin-top: 5px;
          text-align: center;
          border-radius: 20px;
          background: #fff;
          line-height: 15px;
          color: #999;
          font-size: 16px;
          cursor: pointer;
      }
    }
    // .subText{
    //     width: 100%;        
    //     white-space: nowrap;
    //   }
  
  }
}

.subText {
  
  // white-space: nowrap;
}
</style>