var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message__video" },
    [
      _c(
        "div",
        {
          staticClass: "message__video--content",
          on: {
            click: function($event) {
              return _vm.handlerPlayVideo()
            }
          }
        },
        [
          _c("i", { staticClass: "me-icon-playVideo" }),
          _c("Thumb", {
            attrs: { src: _vm.formartImageUrl(_vm.message.imgUrl), margin: "0" }
          })
        ],
        1
      ),
      _c(
        "van-overlay",
        {
          attrs: { show: _vm.show },
          on: {
            click: function($event) {
              return _vm.handlerHiddenVideo()
            }
          }
        },
        [
          _c("video", {
            ref: "video",
            staticClass: "message__video--content--video",
            attrs: {
              controls: "controls",
              preload: "preload",
              poster: _vm.message.url,
              src: _vm.message.url
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }