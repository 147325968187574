<template>
  <div class="message__notice">
    <div class="message__notice--original" v-html="message.text"></div>
    <!-- <span v-if="message.translate" class="message__notice--subDate" v-html="message.translate"></span> -->
  </div>
</template>
<script>
export default {
  name: "message-notice",
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    // console.log(this.message);
  },
  methods: {}
};
</script>
<style lang="stylus" >
@import '~styles/utils/index';

+b(message__notice) {
  width: 100%;
  text-align: center;

  +m(original) {
    max-width: 100%;
    box-sizing: border-box;
    padding: 0.2rem 0.9rem;
    background: #e5e5e5;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    flex-flow: row;
    font-size: 12px;
    display: inline-block;
    img {
      margin: 0 0.2rem;
      // max-width: 15px;
      max-height: 15px;
      object-fit: cover;
    }
  }

  +m(subDate) {
    padding: 0.5rem;
    background: #c6edcc;
    color: #333333;
  }
}
</style>