export function isObject(val) {
  return val != null && typeof val === "object" && Array.isArray(val) === false;
}
export function isEmptyObject(val) {
  try {
    return JSON.stringify(val) != "{}";
  } catch (e) {
    return true;
  }
}
export function isString(val) {
  return typeof val === "string";
}
