var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message__notice" }, [
    _c("div", {
      staticClass: "message__notice--original",
      domProps: { innerHTML: _vm._s(_vm.message.text) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }