var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message__voice" }, [
    _c(
      "div",
      { staticClass: "message__voice--content" },
      [
        _vm.playing
          ? _c(
              "van-circle",
              {
                staticClass: "message__voice--progress",
                attrs: {
                  rate: _vm.percentage,
                  size: "40px",
                  color: "#33cc66",
                  "layer-color": "#f5f5f5",
                  "stroke-width": 60
                },
                model: {
                  value: _vm.percentage,
                  callback: function($$v) {
                    _vm.percentage = $$v
                  },
                  expression: "percentage"
                }
              },
              [_c("i", { staticClass: "me-icon-pause" })]
            )
          : _c("i", {
              staticClass: "me-icon-playVoice",
              on: {
                click: function($event) {
                  return _vm.play()
                }
              }
            }),
        _c("span", [_vm._v(_vm._s(_vm.message.duration) + "''")]),
        _c("div", { staticClass: "original" }, [
          _c("audio", {
            ref: "voice",
            attrs: { preload: "preload", src: _vm.message.url }
          }),
          _vm.message.msgs
            ? _c("span", [
                _vm._v(_vm._s(_vm.message.msgs.speechConvertMessage))
              ])
            : _vm._e()
        ])
      ],
      1
    ),
    _vm.translateMsg.length
      ? _c(
          "ul",
          { staticClass: "message__voice--translist" },
          [
            _vm._l(_vm.translateMsg, function(item, index) {
              return [
                item.language !== _vm.message.msgs.sourceLanguage
                  ? _c(
                      "li",
                      {
                        key: index,
                        class: [
                          "_item-msg",
                          _vm.handlerHasMore(item) ? "trans" : ""
                        ],
                        attrs: { "data-hasmore": _vm.handlerHasMore(item) },
                        on: { click: _vm.handlerToggleShowall }
                      },
                      [
                        _c("span", { staticClass: "subText" }, [
                          _vm._v(_vm._s(item.translateMsg))
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }