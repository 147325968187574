import http from "@/utils/http";
/**
 * @param {string} fileName 文件原名称
 * @param {string} bucket video|image|document|imfile|tfile
 */
export function getUploadToken(fileName, bucket) {
  return http({
    baseURL: "/",
    url: "qiniu/api/getUploadToken",
    method: "post",
    params: {
      policyJsonStr: {
        fileName
      },
      bucket
    }
  });
}
