<template>
  <div class="message__voice">
    <div class="message__voice--content">
      <van-circle
        class="message__voice--progress"
        v-if="playing"
        v-model="percentage"
        :rate="percentage"
        size="40px"
        color="#33cc66"
        layer-color="#f5f5f5"
        :stroke-width="60"
      >
        <i class="me-icon-pause"></i>
      </van-circle>
      <i v-else class="me-icon-playVoice" @click="play()"></i>
      <span>{{ message.duration }}''</span>
       <div class="original">
        <audio preload="preload" :src="message.url" ref="voice" />
        <span v-if="message.msgs">{{message.msgs.speechConvertMessage}}</span>
      </div>
    </div>
   
    <ul v-if="translateMsg.length" class="message__voice--translist">
      <template v-for="(item, index) in translateMsg" >
      <li v-if="item.language !== message.msgs.sourceLanguage" :key="index" :class="['_item-msg',handlerHasMore(item) ? 'trans':'']" 
      :data-hasmore="handlerHasMore(item)" 
       @click="handlerToggleShowall"
     >
        <span class="subText">{{item.translateMsg}}</span>
      </li>
      </template>
    </ul>
  </div>
</template>
<script>
import { formartImageUrl } from "@/utils/help";
import Jquery from 'jquery'
export default {
  name: "message-notice",
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data() {
    this.trimer = null;
    return {
      playing: false,
      percentage: 0,
      translateMsg: []
    };
  },
  created() {
    this.translateMsg = this.sortSelfTranslate(
      this.message.msgs
    );
  },
  watch:{
    message:{
      handler(val){
        this.translateMsg = this.sortSelfTranslate(val.msgs);
        this.$forceUpdate()
      },
      deep:true
    }
  },
  mounted() {},
  methods: {
    formartImageUrl,
      handlerToggleShowall(event){
      const isSelf = Jquery(event.target).attr('class').indexOf('_item-msg') != -1 ? true : false       
      const parent = isSelf ? Jquery(event.target) : Jquery(event.target).parents('._item-msg')
      const hasmore = parent.data('hasmore')
      if(hasmore){
        // const classList = event.target.classList
        const parentClass = parent.attr('class')
        if(parentClass.indexOf('trans') !== -1)
          parent.addClass('showall').removeClass('trans')
        else parent.addClass('trans').removeClass('showall')
      }
    },
    handlerHasMore(item){
      return !item.self && item.translateMsg.length > (item.language !== 8 ? 26 : 20)
    },
 sortSelfTranslate(message) {
      let msgs = message.msgs;
      let temparray = [];
      let selfTransmsg = {}
      let tranTarget = localStorage.getItem('tranTarget') || 'zh'
      if(tranTarget === 'zh') tranTarget = 'zh-CN'
      if(tranTarget === 'ru') tranTarget = 'ru-RU'
      if (msgs) {
        msgs.map(item => {
          console.log(item.language === message.sourceLanguage,'/item');
          
          if (
          item.language !== this.message.sourceLanguage &&
          item.translateMsg &&
          item.language ==
            this.$i18n.messages[tranTarget].code
        ){ 
           selfTransmsg = { ...item, self: true };
        }         
        else if(item.language !== message.sourceLanguage)   
            temparray.push(item);  
        });
        console.log(selfTransmsg,'/selfTransmsg');
        
        if (Object.keys(selfTransmsg).length) temparray.unshift(selfTransmsg);
      }
      return temparray;
    },
    play() {
      // if (this.$refs.voice.duration) {
      this.$refs.voice.play();
      this.startProgress();
      this.playing = true;
      // }
      // this.progress =  this.$refs.voice.currentTime/ this.$refs.voice.duration
    },
    pause() {
      this.$refs.voice.pause();
      this.playing = false;
      if (
        this.$refs.voice &&
        this.$refs.voice.currentTime &&
        this.$refs.voice.currentTime > 0
      ) {
        this.$refs.voice.currentTime = 0;
      }
      clearInterval(this.timer);
    },
    startProgress() {
      const duration = this.message.duration;
      this.timer = setInterval(() => {
        this.percentage = Math.ceil(
          (this.$refs.voice.currentTime / duration) * 100
        );
        if (this.percentage >= 100) {
          this.percentage = 100;
          setTimeout(() => {
            this.pause();
          }, 500);
          return;
        } 
      }, 1000 / 60);
    }
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

+b(message__voice) {
  width: 100%;
  padding: 0.5rem;

  +m(translist) {
    li {
      list-style: none;
      border-top: 1px solid rgba(255,255,255,.5);     
      padding:.5rem 0;
      font-size:13px;

    &.trans{
      display flex;
      flex-flow row;
      align-items center;
      overflow: hidden;
      white-space:nowrap;
      word-break break-all
      .subText{
        // flex:1
        max-width: 92%;
        width:92%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &::after{
          font-family: iconfont!important;
          content: "\E694";
          margin-top: 5px;
          text-align: center;
          border-radius: 20px;
          background: #fff;
          line-height: 15px;
          color: #999;
          font-size: 16px;
          cursor: pointer;
      }     
    }
    &.showall{
       white-space: wrap !important;
        &::after{
          font-family: iconfont!important;
          content: "\e80a" !important;
          margin-top: 5px;
          text-align: center;
          border-radius: 20px;
          background: #fff;
          line-height: 15px;
          color: #999;
          font-size: 16px;
          cursor: pointer;
      }
    }
    }
  }

  +m(progress) {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #33cc66;
  }

  +m(content) {
    // min-width: 100px;
    display: flex;
    flex-flow: row;
    align-items: center;

    .me-icon-playVoice {
      // font-size: 30px;
      margin-right: 0.5rem;
      color: #a2a2a2;
      width: 40px;
      height: 40px;
      min-width:40px;
      min-height:40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #e5e5e5;
      border-radius: 5rem;
      font-size: 20px;
    }
    .original{
      display:flex;
      flex-flow:row;
      margin-left:.9rem;
      line-height:22px;
      padding-right .5rem
    }
  }
}
</style>
