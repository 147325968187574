var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "messageText", staticClass: "message__text" },
    [
      _c("span", {
        staticClass: "message__text--original",
        domProps: {
          innerHTML: _vm._s(_vm.replaceNameToImage(_vm.renderMsg.text))
        }
      }),
      _vm._l(_vm.translates, function(item, index) {
        return [
          _vm.renderMsg.msgs &&
          item.language !== _vm.renderMsg.msgs.sourceLanguage
            ? _c(
                "div",
                {
                  key: index,
                  class: [
                    "message__text--subDate",
                    _vm.handlerHasMore(item) ? "trans" : ""
                  ],
                  attrs: { "data-hasmore": _vm.handlerHasMore(item) },
                  on: { click: _vm.handlerToggleShowall }
                },
                [
                  _c("span", {
                    key: index,
                    staticClass: "subText",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.replaceNameToImage(item.translateMsg)
                      )
                    }
                  })
                ]
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }