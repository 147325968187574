var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "me-chat-msg-action",
      on: {
        click: function($event) {
          _vm.showMenu = false
        }
      }
    },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        }),
        !_vm.params.notice
          ? _c("div", {
              staticClass: "me-chat-msg-action__chatName",
              attrs: { slot: "center" },
              domProps: {
                innerHTML: _vm._s(
                  _vm.params.name && _vm.params.name.replace(/<\/?.+?\/?>/g, "")
                )
              },
              slot: "center"
            })
          : _c("i", {
              staticClass: "me-icon-bell",
              attrs: { slot: "center" },
              slot: "center"
            }),
        _vm.langsType
          ? _c(
              "span",
              {
                attrs: { slot: "right-two" },
                on: {
                  click: function($event) {
                    return _vm.$router.push({
                      name: "langs",
                      params: { type: _vm.params.type, target: _vm.tranTarget }
                    })
                  }
                },
                slot: "right-two"
              },
              [
                _vm._v(
                  _vm._s(_vm.$i18n.messages[_vm.langsType].langs[_vm.langsType])
                )
              ]
            )
          : _vm._e(),
        _vm.params.type === "group" && !_vm.params.notice
          ? _c("i", {
              staticClass: "me-icon-group",
              attrs: { slot: "right-one" },
              on: {
                click: function($event) {
                  return _vm.$router.push({
                    name: "chatGroupDetail",
                    params: { id: _vm.params.id }
                  })
                }
              },
              slot: "right-one"
            })
          : !_vm.params.notice
          ? _c("i", {
              staticClass: "me-icon-addGroup",
              attrs: { slot: "right-one" },
              on: {
                click: function($event) {
                  return _vm.handlerUserCreateGroup()
                }
              },
              slot: "right-one"
            })
          : _vm._e()
      ]),
      _vm.params.type === "group" && !_vm.params.notice
        ? _c(
            "div",
            { staticClass: "me-chat-msg-action__members" },
            _vm._l(_vm.groupMembers, function(user, index) {
              return _c("Avatar", {
                key: index,
                attrs: {
                  src: user.profile_photo,
                  id: user.provider_id,
                  width: "40px",
                  height: "40px"
                }
              })
            }),
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: {
                onTop: _vm._onTop,
                onBottom: _vm._onBottom
              },
              expression:
                "{\n\t\t\t\tonTop: _onTop,\n\t\t\t\tonBottom: _onBottom\n\t\t\t}"
            }
          ],
          ref: "view",
          class: {
            "me-chat-msg-action__list": true,
            isUser: _vm.params.type !== "group"
          }
        },
        [
          _vm.loading
            ? _c("van-loading", {
                staticClass: "chat-loading",
                attrs: { color: "#33cc66" }
              })
            : _vm._e(),
          _vm._l(_vm.list, function(item, key) {
            return _c(
              "van-cell",
              {
                key: item.id,
                ref: "msgItem-" + key,
                refInFor: true,
                staticClass: "chat-msg-list",
                attrs: { title: item.userName },
                on: {
                  click: function($event) {
                    return _vm.handlersetItem(item)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "van-cell__title--item",
                    attrs: { slot: "title" },
                    slot: "title"
                  },
                  [
                    item.type !== "notice"
                      ? [
                          item.fromUser.id !== _vm.$store.state.User.userinfo.id
                            ? [
                                item.fromUser.id !== "0000"
                                  ? _c("Avatar", {
                                      attrs: {
                                        src: item.fromUser.avatar,
                                        width: "30px",
                                        height: "30px",
                                        id: item.fromUser.id
                                      }
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "van-cell__title--item--right"
                                  },
                                  [
                                    item.fromUser.id !== "0000"
                                      ? _c("div", { staticClass: "base" }, [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.fromUser.displayName)
                                            )
                                          ]),
                                          _c("span", { staticClass: "time" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.parseTime(item.sendTime)
                                              )
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "message" },
                                      [
                                        _c("message-" + item.type, {
                                          tag: "component",
                                          attrs: { message: item }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            : [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "van-cell__title--item--right isMe"
                                  },
                                  [
                                    item.fromUser.id !== "0000"
                                      ? _c("div", { staticClass: "base" }, [
                                          _c("span", { staticClass: "time" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.parseTime(item.sendTime)
                                              )
                                            )
                                          ]),
                                          _c("span", { staticClass: "name" }, [
                                            _vm._v(
                                              _vm._s(item.fromUser.displayName)
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      { staticClass: "message" },
                                      [
                                        _c("message-" + item.type, {
                                          tag: "component",
                                          attrs: { message: item }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                item.fromUser.id !== "0000"
                                  ? _c("Avatar", {
                                      staticStyle: {
                                        "margin-right": "0",
                                        "margin-left": ".5rem"
                                      },
                                      attrs: {
                                        src: item.fromUser.avatar,
                                        width: "30px",
                                        height: "30px",
                                        id: item.fromUser.id
                                      }
                                    })
                                  : _vm._e()
                              ]
                        ]
                      : _c("message-" + item.type, {
                          tag: "component",
                          attrs: { message: item }
                        })
                  ],
                  2
                )
              ]
            )
          })
        ],
        2
      ),
      !_vm.params.notice &&
      (_vm.params.type === "group" ? _vm.isGroupMember : true)
        ? _c("ActionInput", { on: { uploading: _vm.handleraddMsg } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }