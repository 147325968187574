var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message__file",
      on: {
        click: function($event) {
          _vm.show = true
        }
      }
    },
    [
      !_vm.message.url && _vm.message.status === "send_going"
        ? _c("van-loading", { staticClass: "imgLoading" })
        : _vm._e(),
      _vm.message.url
        ? _c("Thumb", {
            attrs: {
              src: _vm.formartImageUrl(_vm.message.url),
              width: "auto",
              height: "120px"
            }
          })
        : _vm._e(),
      _c("van-image-preview", {
        attrs: { images: [_vm.formartImageUrl(_vm.message.url)] },
        on: { change: _vm.onChange },
        model: {
          value: _vm.show,
          callback: function($$v) {
            _vm.show = $$v
          },
          expression: "show"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }