<template>
    <div class="me-msg-link" @click="handlerToDetali">
      <Thumb :src="message.shareData.picurl" width="70px" height="70px" v-if="message.shareData.picurl" />
      <div class="info">
          <span class="title" v-html="message.shareData.title"></span>
          <p class="desc" v-html="message.shareData.briefInfo"></p>
      </div>
    </div>
</template>
<script>
import Thumb from "@/components/common/thumb";
export default {
    name:'link',
    components:{Thumb},
    props:{
        message:{
            type:Object,
            default:()=>{return {}}
        }
    },
    created(){
        // console.log(this.message);
    },
    methods:{
        handlerToDetali(){
            const {serviceType,uuid,pageTab} =this.message.shareData 
            let name = ''
            let type = ''
            let active = null
            switch(serviceType){
                case 'service':
                    name = 'detail'
                    break
                case 'people':
                    name = 'user'
                    if(pageTab){
                        active = pageTab
                    }else{
                        active = 0
                    }
                    break
                case 'group':
                    name = 'group'
                    break
                case 'company':
                    name = 'detail'
                    type = 'enterprise'
                    break
            }
            let route = {
                name,
                params:{ id:"pai_" + Base64.encode(uuid) }
            }
            if(type !== '') route.params.type = type;
            if(active !== null) route.params.active = active;
            this.$router.push(route)
        }
    }
}
</script>
<style lang="stylus">
    .me-msg-link{
        padding .5rem;
        display flex;
        flex-flow row
        .van-image{
            // margin 0 !important
        }
        .info{
            .title{
                font-size 15px;
                color #333333
            }
            .desc{
                font-size 13px
                color #666666
                padding 0 
                margin 0
                line-height 22px
            }
        }
    }
</style>