var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-action-input", attrs: { id: "myinput" } },
    [
      _c(
        "div",
        { staticClass: "inner" },
        [
          _c(
            "van-popup",
            {
              attrs: { position: "bottom", "get-container": "#myinput" },
              model: {
                value: _vm.show,
                callback: function($$v) {
                  _vm.show = $$v
                },
                expression: "show"
              }
            },
            [
              _c(
                "ul",
                { staticClass: "me-action-input__emotion" },
                _vm._l(_vm.items, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function($event) {
                          _vm.addEmotionImg(
                            $event,
                            item,
                            _vm.getFile(item.name)
                          )
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.getFile(item.name),
                          "face-name": item.name
                        }
                      })
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _c("div", {
            ref: "textarea",
            staticClass: "me-action-input__editor",
            attrs: { contenteditable: "" },
            on: {
              blur: _vm._inputBlur,
              input: _vm._inputListen,
              click: function($event) {
                _vm.show = false
              }
            }
          }),
          _c("button", { staticClass: "add-emotion-btn" }, [
            _c("i", {
              staticClass: "me-icon-smill icon",
              on: {
                click: function($event) {
                  return _vm.showEmotion()
                }
              }
            })
          ]),
          !_vm.content
            ? _c(
                "van-uploader",
                { attrs: { "after-read": _vm.afterUploader, multiple: true } },
                [_c("i", { staticClass: "me-icon-plus-border icon" })]
              )
            : _vm.loading
            ? _c("van-loading", {
                staticClass: "send-loading icon",
                attrs: { size: "32px", color: "#ffffff" }
              })
            : _c("i", {
                staticClass: "me-icon-sender icon",
                on: {
                  click: function($event) {
                    _vm.curUser.type === "group"
                      ? _vm.handlerSenderGroupMsg()
                      : _vm.handlerSenderChatMsg()
                  }
                }
              })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }