<template>
  <div class="message__video">
    <div class="message__video--content" @click="handlerPlayVideo()">
      <i class="me-icon-playVideo"></i>
      <Thumb :src="formartImageUrl(message.imgUrl)" margin="0" />
    </div>
    <van-overlay :show="show" @click="handlerHiddenVideo()">
      <video
        class="message__video--content--video"
        controls="controls"
        preload="preload"
        :poster="message.url"
        :src="message.url"
        ref="video"
      />
    </van-overlay>
  </div>
</template>
<script>
import Thumb from "@/components/common/thumb";
import { formartImageUrl } from "@/utils/help";
export default {
  name: "message-notice",
  components: {
    Thumb
  },
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: false
    };
  },
  created() {},
  methods: {
    formartImageUrl,
    handlerPlayVideo() {
      this.show = !this.show;
      this.$refs.video.play();
    },
    handlerHiddenVideo() {
      this.show = !this.show;
      this.$refs.video.pause();
    }
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

+b(message__video) {
  width: 100%;
  text-align: center;

  .van-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3 !important;
  }

  +m(content) {
    padding: 0.5rem;
    display: flex;

    .me-icon-playVideo {
      z-index: 1;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 0.2);
      -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.6);
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      padding: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all ease-in-out 0.2s;
      transition: all ease-in-out 0.2s;
      font-size: 24px;

      &:hover {
        -webkit-transform: translate(-50%, -50%) scale(1.2);
        transform: translate(-50%, -50%) scale(1.2);
        color: #fff;
        background: rgba(255, 255, 255, 0.1);
      }
    }

    +m(video) {
      width: 85%;
      object-fit: cover;
    }

    .van-image {
      margin: 0 !important;
    }
  }
}
</style>
