var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-msg-link", on: { click: _vm.handlerToDetali } },
    [
      _vm.message.shareData.picurl
        ? _c("Thumb", {
            attrs: {
              src: _vm.message.shareData.picurl,
              width: "70px",
              height: "70px"
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "info" }, [
        _c("span", {
          staticClass: "title",
          domProps: { innerHTML: _vm._s(_vm.message.shareData.title) }
        }),
        _c("p", {
          staticClass: "desc",
          domProps: { innerHTML: _vm._s(_vm.message.shareData.briefInfo) }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }